import * as React from 'react';

import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';

import Card from '../components/Card';
import Signature from '../components/Signature';
import Title from '../components/Title';

const NotFoundPage = () => (
  <Layout pagetitle="Josh">
    <PageHeader>
      <Title>404</Title>
    </PageHeader>
    <Card>
      <p>This is not the page you're looking for.</p>
      <Signature />
    </Card>
  </Layout>
);

export default NotFoundPage;
